<template>
  <v-card class="flex d-flex flex-column"  style="align-items: stretch; align-content: stretch; align-self: stretch" :disabled="disabled">
    <div>
      <v-checkbox class="select_checkbox"
                  color="success"
                  hide-details
                  :disabled="disabled"
                  :value="item.id"
                  v-model="selected"
                  @click="updateSelected"
      ></v-checkbox>
      <v-img
        class="white--text align-end"
        :src="item.imagePath"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
        contain
      >
      </v-img>
    </div>
    <v-card-title style="cursor: pointer;" @click="goToProduct">{{ item.label }}</v-card-title>
    <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >
        <p> Reference : {{ item.reference }}</p>
      </v-row>
      <div class="my-4 subtitle-1">
        Prix : {{ helpers.toEuro(item.price_taxless) }}
      </div>
      <div class="h2" v-if="!_.isEmpty(item.description_short)"> {{ item.description_short }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'ProductComponent',
  data: () => ({
    selected: null,
    disabled: false
  }),
  props: {
    item: {
      required: true
    },
    loading_from_parent: {
      type: Boolean,
      required: false
    },
    additive_from_parent: null, // array of product ids that are additive
    selected_from_parent: null // array of all items selected or not from parent
  },
  methods: {
    // EVENTS :

    /**
     * Listener : 'SiteCatalogueAddProductComponent', 'SiteFamilyProductSelectComponent'
     * Event name : 'selected'
     * Payload: Type of operation : 'added' or 'removed' &&  Item involved : item.id
     *
     * */
    updateSelected () {
      if (this.selected) {
        this.$emit('selected', { type: 'added', item: this.item.id })
      } else {
        this.$emit('selected', { type: 'removed', item: this.item.id })
      }
    },
    goToProduct () {
      if (!this.loading_from_parent) {
        this.$router.push({ name: 'GeneralProductsOne', params: { id: this.item.id } })
      }
    }
  },
  watch: {
    // react to parent select all || unselect all
    selected_from_parent: function (val) {
      if (!this.disabled) {
        if (_.includes(this.selected_from_parent, this.item.id)) {
          this.selected = this.item.id
        } else this.selected = null
      }
    },
    // react to changes of product additive
    additive_from_parent: function (val) {
      this.disabled = _.includes(this.additive_from_parent, this.item.id)
    },
    // react to loading from parent in case product is not additive
    loading_from_parent: function (val, oldVal) {
      if (!_.includes(this.additive_from_parent, this.item.id)) {
        this.disabled = this.loading_from_parent
      }
    }
  },
  mounted () {
    // pre-select items that have already been selected
    if (_.includes(this.selected_from_parent, this.item.id)) {
      this.selected = this.item.id
    } else this.selected = null

    this.disabled = this.loading_from_parent

    // if product is additive, disable it
    if (_.includes(this.additive_from_parent, this.item.id)) {
      this.disabled = true
    } else { this.disabled = this.loading_from_parent }
  }
}
</script>

<style scoped>

.v-sheet.v-card:not(.v-sheet--outlined) {
  margin: 20px 0;
}

.select_checkbox {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}

</style>
