<template>
  <v-card :loading="ui.loading" class="overflow-hidden" height="80vh">
    <v-card-text class="mt-8">
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-icon @click="close">mdi-close</v-icon>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="search"
            :label="$vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.search')"
            solo
            :disabled="disableButtons"
            hide-details
            @click="fetchAll"
            append-icon="mdi-magnify"
            dense></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-2">
          <v-btn class="mr-4" @click="selectAll" :disabled="disableButtons">
            <span class="hidden-sm-and-down">{{
                $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.select_all')
                                             }}</span>
            <v-icon right color="green">
              mdi-checkbox-multiple-marked-outline
            </v-icon>
          </v-btn>

          <v-btn @click="unselectAll" :disabled="disableButtons">
            <span class="hidden-sm-and-down">{{
                $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.unselect_all')
                                             }}</span>
            <v-icon right color="orange">
              mdi-checkbox-multiple-blank-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-alert
            v-model="ui.alert.enable"
            :dismissible="ui.alert.dismissible"
            :icon="ui.alert.icon"
            :type="ui.alert.type"
            class="mb-0 mb-3"
          >
            {{ ui.alert.message }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="overflow-auto mb-7 d-flex" style="max-height: 600px"
             ref="products" v-on:scroll="handleInfiniteScroll">
        <v-col v-if="_.isEmpty(filteredProducts)">
          <v-img
            height="300"
            contain
            src="@/assets/no_results_found.png"
          ></v-img>
          <v-card-text class="text-center overline justify-center">
            <p class="mb-0">
              {{
                $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.no_results')
              }}
            </p>
          </v-card-text>
        </v-col>
        <v-col cols="3" sm="12" md="3" xs="12" class="pb-0 d-flex flex-column" :class="{'opacity': ui.infiniteLoading }"
               v-for="product in filteredProducts" :key="product.id">
          <v-progress-circular class="infiniteLoading"
                               v-if="ui.infiniteLoading"
                               :size="50"
                               color="amber"
                               indeterminate
          ></v-progress-circular>
          <product-component style="margin-right: 20px"
                                                      :loading_from_parent="ui.loading"
                                                      :selected_from_parent="form.data.selected_products"
                                                      @selected="updateProductsSelectedFromFamily"
                                                      :item="product"
          >
          </product-component>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="reset">
        {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="save">
        {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import _ from 'lodash'
import ProductComponent from './ProductComponent'

export default {
  name: 'FamilyProductSelectComponent',
  components: { ProductComponent },
  props: {
    onCancel: {
      type: Function,
      required: true
    },
    site: {
      required: true // site id
    },
    family: {
      required: true // family id
    },
    selected_from_parent_refine_component: Array
  },
  data: () => ({
    ui: {
      loading: false,
      infiniteLoading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    page: 1, // first page, used for paginating results
    last_page: '', // last page
    perPage: 8, // how many results per page
    total: '', // total results
    search: '', // search term
    form: {
      valid: false,
      data: {
        selected_products: [], // array of product ids to be added
        removed_products: [] // array of product ids to be removed
      },
      rules: {}
    },
    products: [] // list of all products available
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    reset () {
      this.search = ''
      this.form.data.selected_products = this.selected_from_parent_refine_component
      this.ui.alert.enable = false
    },
    close () {
      this.search = ''
      this.onCancel()
      this.ui.alert.enable = false
    },
    /**
     * Add event listener for infinite scroll on products
     * */
    handleInfiniteScroll () {
      if (this.$refs.products.scrollTop + this.$refs.products.clientHeight >= this.$refs.products.scrollHeight && this.page <= this.last_page) {
        this.fetchProducts(this.page, this.perPage)
      }
    },
    // API
    /**
     * Fetches products that belong to the selected family and that are available for site
     *
     * @param page Page number that we wish to fetch the results for
     * @param perPage How many results per page
     * @param loadAllProducts Whether or not to load all remaining products (ignore pagination)
     * @return {Promise<void>}
     */
    async fetchProducts (page, perPage, loadAllProducts) {
      try {
        this.ui.infiniteLoading = true
        this.response = (await this.axios.post('/site/' + this.$route.params.id + '/catalogue/family/' + this.family + '/products?page=' + page, {
          per_page: perPage, // how many results per page
          loadAllProducts: loadAllProducts
        }, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data

        // if we should load all remaining products
        if (loadAllProducts) {
          _.forEach(this.response, product => {
            this.products.push(product)
          })
        } else {
          // push into array of products
          _.forEach(this.response.data, product => {
            this.products.push(product)
          })
          // save last page
          this.last_page = this.response.last_page
          // total number of records
          this.total = this.response.total
          // increment current page
          this.page = this.page + 1
        }
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            // site not found
            case 404:
              await this.$router.push({
                name: 'Home',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.notification.site_not_found')
                  }
                }
              })
              break

            // product family not found
            case 405:
              this.close()
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.notification.product_family_not_found'))
              break

            default:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.notification.load'))
              break
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.infiniteLoading = false
      }
    },
    /**
     *  Selects all products (fetches all first and then selects)
     * */
    async selectAll () {
      await this.fetchAll()
      this.form.data.selected_products = this.products.map(p => p.id)
    },
    /**
     *  Unselects all products (fetches all first and then unselects)
     * */
    async unselectAll () {
      await this.fetchAll()
      this.form.data.selected_products = []
    },
    /**
     * Fetches all products and ignores pagination
     * */
    async fetchAll () {
      if (_.size(this.products) !== this.total) {
        this.page = this.last_page + 1
        await this.fetchProducts(1, null, _.size(this.products))
      }
    },
    // EVENTS
    /**
     *
     * Emitter : ProductComponentWithCheckbox
     * Event name : 'selected'
     * Payload: {Type : operation type(add||remove), Item: item id}
     *
     * Respond to event sent from child as to whether or not a product has been selected
     * Updates the array of selected products && removed products
     *
     * @param payload Object that contains the type of event ('added' or 'removed') and the item id
     */
    updateProductsSelectedFromFamily (payload) {
      if (payload.type === 'added') {
        this.form.data.selected_products.push(payload.item)
      } else if (payload.type === 'removed') {
        this.form.data.selected_products = this.form.data.selected_products.filter(e => e !== payload.item)
      }
    },
    /**
     * Saves selection of products and sends event to update selected products
     *
     * Receiver : SiteFamilyRefineComponent
     * Event name : 'savedSelectionFamily'
     * Payload sent : array of selected products
     */
    save () {
      this.$emit('savedSelectionFamily', this.form.data.selected_products)
      this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.notification.added'))

      setTimeout(() => {
        this.close()
      }, 1000)
    }
  },
  computed: {
    // search through products
    filteredProducts () {
      return this.products.filter(product => {
        return product.label.toLowerCase().includes(this.search.toLowerCase()) || product.reference.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    // disable buttons if no products
    disableButtons () {
      return _.isEmpty(this.filteredProducts) || this.ui.infiniteLoading
    }
  },
  watch: {
    // update selected products from parent
    selected_from_parent_refine_component: function (val) {
      this.form.data.selected_products = this.selected_from_parent_refine_component
    }
  },
  mounted () {
    // fetches the first perPage products
    this.fetchProducts(this.page, this.perPage)

    this.ui.infiniteLoading = true

    if (!_.isUndefined(this.initial_selected_products)) {
      this.form.data.selected_products = this.selected_from_parent_refine_component
    }
  }
}
</script>

<style scoped>
.infiniteLoading {
  text-align: center;
  position: absolute;
  z-index: 9;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.opacity {
  opacity: 0.6
}

</style>
