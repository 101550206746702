<template>
  <v-hover v-slot="{ hover }">
  <v-card class="mr-3 hover-effect"  :elevation="hover ? 12 : 2">
    <v-card-title class="text-center overline justify-center">
      <img class="mt-4" src="@/assets/box.png" style="height: 70px">
    </v-card-title>
    <v-card-text class="text-center overline justify-center">
      <p class="mb-0">
        {{ family.path }}
      </p>
      <p>
        {{ totalProducts }} {{ $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_card.products_total') }}
      </p>
    </v-card-text>
    <v-card-actions class="mt-4 pb-7">
      <v-row align="stretch" justify="space-around">
        <v-checkbox
          v-model="import_all"
          label="Tout importer"
          color="success"
          :value="import_all"
          hide-details
        ></v-checkbox>
        <v-dialog
          width="80%"
          height="80%"
          v-model="dialog_refine_products"
          persistent>
          <FamilyProductSelectComponent :site="$route.params.id"
                                            :family="family.id"
                                            :on-cancel="()=>{dialog_refine_products = false}"
                                            :selected_from_parent_refine_component = pre_selected
                                            @savedSelectionFamily="saveProducts"></FamilyProductSelectComponent>
        </v-dialog>
        <v-btn
          @click="dialog_refine_products = true"
          tile
          small
          :disabled="import_all"
          color="success"
        >
          <v-icon left>
            mdi-pencil
          </v-icon>
          Affiner
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
  </v-hover>
</template>

<script>
import _ from 'lodash'
import FamilyProductSelectComponent from './FamilyProductSelectComponent'

export default {
  components: { FamilyProductSelectComponent },
  name: 'FamilyRefineComponent',
  props: {
    family: {
      type: Object,
      required: true
    },
    pre_selected: { // array of already selected product ids that belong to the family
      type: Array,
      required: false
    },
    should_reset: Boolean
  },
  data: () => ({
    dialog_refine_products: false, // opens dialog to refine products
    import_all: false, // import all products from family
    products: [], // array of selected products from component
    removed_products: [] // array of removed products
  }),

  methods: {
    /**
     * EVENT RECEIVED :
     *
     * Emitter : SiteFamilyProductSelectComponent
     * Event name : 'savedSelectionFamily'
     * Payload received: array of selected products
     *
     * Saves products that have been selected by SiteFamilyProductSelectComponent
     * Saves products that have been removed
     *________________________________________________________________
     * EVENT SENT :
     *
     * Receiver : Edit
     * Event name : 'updateSelectedProductsFromFamily'
     * Payload sent : array of selected products && removed products
     *
     * @param payload
     */
    saveProducts (payload) {
      this.removed_products = _.difference(this.pre_selected, payload)
      this.products = payload
      this.$emit('updateSelectedProductsFromFamily', { added: this.products, removed: this.removed_products })
    }
  },
  computed: {
    // counts selected products
    totalProducts () {
      if (this.import_all) {
        return this.family.total
      } else return _.size(this.products)
    }
  },
  watch: {
    // watch selected products array of ids change from parent (if we save changes from another component)
    pre_selected () {
      this.products = this.pre_selected
    },
    should_reset () {
      if (!_.isUndefined(this.family.is_additive)) {
        this.family.is_additive === 1 ? this.import_all = true : this.import_all = false
      }
      this.products = this.pre_selected
    }
  },
  mounted () {
    if (!_.isUndefined(this.family.is_additive)) {
      this.family.is_additive === 1 ? this.import_all = true : this.import_all = false
    }

    this.products = this.pre_selected
  }
}
</script>

<style scoped>

.h2 {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 400px;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  -webkit-line-clamp: 3;
}

.v-input--selection-controls {
  margin-top: 0;
}

.v-sheet.v-card:not(.v-sheet--outlined, .v-dialog .v-card){
  margin: 20px 0;
  width: 380px;
  max-width: 380px;
}

</style>
